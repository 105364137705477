/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { preventScientificNotation } from 'crakn/utils/numeric-input-helpers';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

const Validations = buildValidations({
  appliedBy: validator('presence', {
    presence: true,
    message: reads('model.requiredFieldValidationMessage')
  }),
  dateApplied: validator('presence', {
    presence: true,
    message: reads('model.requiredFieldValidationMessage')
  }),
  gracePeriod: validator('number', {
    allowString: true,
    gte: 0,
    message: reads('model.gracePeriodValidationMessage'),
  }),
  interestAmount: validator('number', {
      allowString: true,
      gt: 0.00,
      message: reads('model.interestAmountValidationMessage'),
  }),
  selectionId: validator('presence', {
    presence: true,
    message: reads('model.requiredFieldValidationMessage')
  }),
});

export default class KasesManageFinancialsInterestApplyController extends Controller.extend(Validations) {
  @service intl;
  @service session;
  @service staff;

  @tracked appliedBy;
  @tracked dateApplied;
  @tracked gracePeriod = 0;
  @tracked interestAmount = 0;
  @tracked invoiceAddOnDate;
  @tracked note;
  @tracked selection = null;
  @tracked selectionId = null;
  @tracked showValidation = false;
  
  preventScientificNotation = preventScientificNotation;

  get gracePeriodValidationMessage() {
    return this.intl.t('authenticated.kases.manage.financials.interest.apply.messages.validation.gracePeriod');
  }

  get interestAmountValidationMessage() {
    return this.intl.t('authenticated.kases.manage.financials.interest.apply.messages.validation.interestAmount');
  }

  get requiredFieldValidationMessage() {
    return this.intl.t('authenticated.kases.manage.financials.interest.apply.messages.validation.requiredField');
  }

  get interestStartDate() {
    return this.invoiceAddOnDate ? moment(this.invoiceAddOnDate).add(this.gracePeriod, 'days'): null;
  }
 

  @action
  applyInterest() {
    this.validate().then(({validations}) => {
      if (validations.get('isValid')) {
        set(this, 'showValidation', false);
      }
      else{
        set(this, 'showValidation', true);
      }
    });
  }

  @action
  setSelection(value) {
    set(this, 'selectionId', value);
    const selection = this.model.findBy('id', value);
    set(this, 'selection', selection);
    set(this, 'invoiceAddOnDate', selection?.contractDate ? moment(selection.contractDate) : null);
  }
}
