import EmberRouter from '@ember/routing/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function() {
  this.route('forgot-password');
  this.route('help');
  this.route('debug');
  this.route('legal');
  this.route('login');
  this.route('logout');
  this.route('reset-password');
  this.route('accept-new-client');
  this.route('esignatures', function() {
    this.route('show', { path: '/:token' });
    this.route('signed', { path: '/thanks' });
  });

  this.route('authenticated', { path: '' }, function() {
    this.route('agree-to-legal');
    this.route('financial-detail');
    this.route('event-response');
    this.route('calendar', function() {
      this.route('new-event', { path: 'event/:event_id' });
    });
    this.route('exception');

    this.route('esignatures', function() {
    });

    this.route('misc-sales', function() {
      this.route('create');
      this.route('edit', { path: ':selection_id/' }, function() {
        this.route('details');
        this.route('payments');
        this.route('adjustments');
        this.route('discounts');
        this.route('refunds');
      });
    });

    this.route('clients', function() {});

    this.route('kases', { path: '/cases' }, function() {
      this.route('overview', { path: ':kase_id/overview' });
      this.route('create'),

      this.route('manage', { path: '/:kase_id' }, function() {
        this.route('tasks', function() {
          this.route('all');
          this.route('open');
          this.route('complete');
          this.route('disabled');
        });

        this.route('integrations', function() {
          this.route('frazer');
          this.route('aftercare');
          this.route('funeralone');
          this.route('messenger');
          this.route('bass-mollett');
          this.route('ciclops');
          this.route('tribute-print');
          this.route('viewlogies');
          this.route('cfs');
          this.route('quick-books');
          this.route('quick-books-online');
          this.route('sage-50');
          this.route('merrick');
          this.route('precoa');
          this.route('cemsites');
          this.route('batesville');
          this.route('tukios');
          this.route('tukios-v2');
          this.route('best-wishes');
          this.route('frontrunner');
          this.route('tribute-website');
          this.route('funeral-tech');
          this.route('tribute-video');
        });

        this.route('financials', function() {
          this.route('overview');
          this.route('selection', function() {
            this.route('package', { path: 'package/:selection_package_id'});
          });
          this.route('payments', function() {
            this.route('edit', { path: ':selection_id/edit' });
          });
          this.route('adjustments');
          this.route('discounts');
          this.route('refunds');
          this.route('interest', function() {
            this.route('apply');
          });
        });

        this.route('chain-of-custody', function() {
          this.route('audit-log');
          this.route('personal-effect');
          this.route('decline-custody');
        });

        this.route('edit', function() {
          this.route('info');
          this.route('preneed');
          this.route('deceased');
          this.route('documents');
          this.route('personal');
          this.route('transportation');
          this.route('preparations');
          this.route('family-and-friends');
          this.route('veteran-info');
          this.route('inquiries');
          this.route('events', function() {});
          this.route('service-info');
          this.route('referral-info');
          this.route('disposition');
          this.route('obituary');
          this.route('printables');
          this.route('kase-forms');
          this.route('blank-forms');
          this.route('kase-links');
          this.route('family-portal');
          this.route('cremation-authorization');
          this.route('settings');
          this.route('custom-fields');
          this.route('tfe', function() {
            this.route('library');
            this.route('filled');
            this.route('custom-upload');
          });
        });

        this.route('documents', function() {
          this.route('secure-documents');
          this.route('photos');
          this.route('albums', function() {
            this.route('edit', { path: '/:album_id' });
            this.route('manage');
            this.route('new');
          });
        })
      });

      this.route('events', function() {
        this.route('edit', { path: ':event_id/edit' });
      });
    });

    this.route('contacts', function() {
      this.route('new');
      this.route('edit', { path: ':contact_id/edit' });
      this.route('manage', { path: ':contact_id/manage' });
    });

    this.route('inquiries', function() {
      this.route('new');
      this.route('edit', { path: ':inquiry_id/edit' });
      this.route('events', function() {
        this.route('edit', { path: ':event_id/edit' });
      });
    });

    this.route('places', function() {
      this.route('new');
      this.route('edit', { path: ':place_id/edit' });
      this.route('manage', { path: ':place_id/manage' });
    });

    this.route('whiteboard');
    this.route('dashboard');
    this.route('yellow-report');
    this.route('chain-of-custody-details');

    this.route('settings', function() {
      this.route('calendar');
      this.route('info');
      this.route('password');
      this.route('signature');
    });

    this.route('crakn', function() {
      this.route('manage', function() {
        this.route('clients');

        this.route('demos', function() {
          this.route('new');
        });

        this.route('forms', function() {
          this.route('new');
          this.route('edit', { path: ':form_id/edit' });
          this.route('tfe', function() {
            this.route('new');
            this.route('edit', { path: ':document_id/edit' });
          })
        });
        this.route('check-templates', function() {
          this.route('new');
          this.route('edit', { path: ':template_id/edit' });
        });
        this.route('migrations');
        this.route('global-settings');
      });

      this.route('form-keys');

      this.route('clients', function() {
        this.route('client', { path: ':client_id' }, function() {
          this.route('edit', function() {
            this.route('overview');
            this.route('dashboard-config');
            this.route('sfgs-config');
            this.route('soa-config');
            this.route('reports-config');
            this.route('forms-config');
            this.route('receipt-config');
            this.route('call-config');
            this.route('expiration');
          });

          this.route('feature-config');
          this.route('sfgs-config', function() {
            this.route('edit', { path: ':contract_template_id/edit' }, function() {
              this.route('overview');
              this.route('location-access');
            });
          });

          this.route('soa-config', function() {
            this.route('edit', { path: ':soa_id/edit' }, function() {
              this.route('overview');
            });
            this.route('new');
          });
        });
      });
    });

    this.route('admin', function() {
      this.route('manage', function() {
        this.route('financial-configurations', function() {
          this.route('new');
        });

        this.route('general-price-lists', function() {
          this.route('new');
        });

        this.route('groups', function() {
          this.route('new');
          this.route('edit', { path: ':group_id/edit' });
        });

        this.route('locations');

        this.route('organizations', function() {
          this.route('new');
          this.route('edit', { path: ':organization_id/edit' });
        });

        this.route('resources', function() {
          this.route('new');
          this.route('edit', { path: ':resources/edit' });
        });

        this.route('users', function() {
          this.route('new');
          this.route('edit', { path: ':user_id/edit' });
        });

        this.route('online-planner', function() {
          this.route('edit', { path: ':id/edit' });
        });

        this.route('vehicles', function() {
          this.route('new');
          this.route('edit', { path: ':vehicle_id/edit' });
        });

        this.route('cemsites', function() {
          this.route('new');
        });

        this.route('audit-logs');
        this.route('case-numbering');
        this.route('forms');
        this.route('tfe', function() {
          this.route('forms', function() {});
        });
        this.route('import');
        this.route('info');
        this.route('logos');
        this.route('ar-adjustments');
        this.route('obituary-generator');
        this.route('sync');
        this.route('tasks');
        this.route('whiteboard');
        this.route('tribute-video');
        this.route('custom-fields', function() {
          this.route('edit', { path: ':custom_field_id/edit' });
          this.route('new');
        });
      });

      this.route('online-planner', function() {
        this.route('edit', { path: ':id/edit' }, function() {
          this.route('overview');
          this.route('users');
          this.route('page', { path: '/page/*wildcard' });
          this.route('notification-settings');
        });
        this.route('config', { path: 'config/:id' }, function() {
          this.route('edit-category', { path: '/category/:olp_category_id/edit' });
        });
      });

      this.route('users', function() {
        this.route('edit', { path: ':user_id/edit' }, function() {
          this.route('overview');
          this.route('location-access');
          this.route('feature-configuration');
          this.route('dashboard-configuration');
          this.route('permissions');
        });
      });

      this.route('tfe', function() {
        this.route('forms', function() {
          this.route('edit', { path: ':document_id/edit' }, function() {
            this.route('location-access');
          });
        });
      });

      this.route('data', function() {
        this.route('case-tags');
        this.route('cause-of-death');
        this.route('embalm-reasons');
        this.route('event-names');
        this.route('purchase-reasons');
        this.route('relationships');
        this.route('poems-and-prayers');
      });

      this.route('integrations', function() {
        this.route('overview');
        this.route('messenger');
        this.route('precoa');
        this.route('aftercare');
        this.route('forethought');
        this.route('tribute-print');
        this.route('ciclops');
        this.route('cfs');
        this.route('frazer');
        this.route('funeralone');
        this.route('viewlogies');
        this.route('transnational');
        this.route('bass-mollett');
        this.route('funeral-decisions');
        this.route('ohio-edrs');
        this.route('asd');
        this.route('batesville');
        this.route('tukios');
        this.route('tukios-v2');
        this.route('best-wishes');
        this.route('frontrunner');
        this.route('domanicare');
        this.route('funeral-tech');
        this.route('tribute-video');
        this.route('tribute-website');
      });

      this.route('financial-configurations', { path: 'financial-configurations/:chart_of_accounts_id' }, function() {
        this.route('edit', function() {
          this.route('overview');
          this.route('posting-periods');
          this.route('accounts', function() {
            this.route('edit');
          });
          this.route('bank-accounts', function(){
            this.route('new');
            this.route('edit', { path: ':id/edit' });
          });
          this.route('adjustments');
          this.route('payments');
          this.route('discounts');
          this.route('sync');
          this.route('special-taxes');
          this.route('tax-items');
          this.route('additional-settings');
          this.route('interests');
        });
      });

      this.route('general-price-lists', { path: 'general-price-lists/:general_price_list_id' }, function() {
        this.route('edit', function() {
          this.route('overview');
          this.route('statement-categories');
          this.route('preneed-categories');
          this.route('product-categories');
          this.route('products', function() {
            this.route('edit', { path: ':product_id/edit' });
          });
          this.route('packages', function() {
            this.route('edit', { path: ':package_id/edit' }, function() {
              this.route('manage-products', { path: ':package_slot_id/manage-products' });
            });
            this.route('new');
          });
          this.route('price-list-dates');
          this.route('pricing-imports');
          this.route('pricing-updates');
          this.route('cost-updates');
        });
      });

      this.route('locations', function() {
        this.route('edit', { path: ':location_id/edit' }, function() {
          this.route('case-numbering');
          this.route('general-price-list');
          this.route('user-access');
          this.route('logos');
          this.route('overview');
          this.route('special-taxes');
          this.route('taxes');
          this.route('vehicles');
        });
      });
      this.route('tribute-pay', function() {
        this.route('overview');
        this.route('email-templates', function() {
          this.route('edit', { path: ':id/edit' });
        });
        this.route('notification-settings');
      });
      this.route('tribute-pay-payments', function() {
        this.route('accounts', function() {
          this.route('edit', { path: ':id/edit' });
          this.route('new');
        });
      });
    });

    this.route('accounting', function() {
      this.route('exports', function() {
        this.route('new');
      });
    });

    this.route('generate/statement', { path: 'generated_document/:uuid' });
    this.route('generate', function() {
      this.route('statement', { path: 'statement/:uuid' });
    });

    this.route('reports');

    this.route('inventory', function() {
      this.route('edit', { path: ':inventory_id/edit' });
      this.route('manage');
    });

    this.route('websites', function() {});

    this.route('ohio-edrs', function() {});

    this.route('online-planner-plans');
    this.route('online-planner-plans', function() {
      this.route('edit', { path: ':id/edit' });
    });

    this.route('payments', function() {
      this.route('batch-payments', function() {
        this.route('new');
        this.route('edit', { path: ':id/edit' });
      })
      this.route('adjustments');
      this.route('transactions');
      this.route('payouts');
    })
    this.route('batch-payments');
    this.route('batch-payments', function() {
      this.route('new');
    });
    this.route('checks', function() {
      this.route('new');
      this.route('check-details', { path: 'check-details/:check_id' }, function() {
        this.route('edit');
        this.route('audit-log');
        this.route('view');
      });
    });
  });
  this.route('not-found', {path: '/*path'} );
  this.route('pay-now', function() {
    this.route('success', { path: ':success_type/:jwt' });
    this.route('expired', { path: 'expired/:uuid/:is_tms_client' });
  });

  this.route('v2', function() {
    this.route('adjustment');
  });
});
